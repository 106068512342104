<style scoped lang="less">
  .content {
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
  }
  .text-field, .text-value {
    line-height: 3;
    white-space: wrap;
  }
  .row {
    white-space: wrap;
    min-width: 25%;
    word-break: keep-all;
  }
</style>

<template>
  <r-card hborder>
    <span slot="title">基础信息</span>
    <div class="content">
        <div class="row">
          <span class="text-field">性别：</span>
          <span class="text-value">{{data.sex || '-'}}</span>
        </div>
        <div class="row">
          <span class="text-field">出生年月：</span>
          <span class="text-value">{{(data.birth || '-').substr(0, 10)}}</span>
        </div>
        <div class="row">
          <span class="text-field">民族：</span>
          <span class="text-value">{{data.clan || '-'}}</span>
        </div>
        <div class="row">
          <span class="text-field">籍贯：</span>
          <span class="text-value">{{data.hometown || '-'}}</span>
        </div>
        <!-- <div class="row">
          <span class="text-field">婚姻状况：</span>
          <span class="text-value">{{otherInfo.hyzk || '-'}}</span>
        </div> -->
        <div class="row">
          <span class="text-field">入党时间：</span>
          <span class="text-value">{{(otherInfo.rdsj || '-').substr(0, 10)}}</span>
        </div>
        <div class="row">
          <span class="text-field">转正日期：</span>
          <span class="text-value">{{(otherInfo.zzrq || '-').substr(0, 10)}}</span>
        </div>
        <div class="row">
          <span class="text-field">党龄：</span>
          <span class="text-value">{{dage}}</span>
        </div>
        <!-- <div class="row">
          <span class="text-field">参加工作时间：</span>
          <span class="text-value">{{(otherInfo.ckgzsj || '-').substr(0, 10)}}</span>
        </div> -->
        <div class="row">
          <span class="text-field">身份证号：</span>
          <span class="text-value">{{data.idNo || '-'}}</span>
        </div>
        <div class="row">
          <span class="text-field">年龄：</span>
          <span class="text-value">{{age}}</span>
        </div>
        <div class="row">
          <span class="text-field">户籍所在地：</span>
          <span class="text-value">{{otherInfo.hjszd}}</span>
        </div>
        <div class="row">
          <span class="text-field">现居住地：</span>
          <span class="text-value">{{otherInfo.jtzz}}</span>
        </div>
        <!-- <div class="row">
          <span class="text-field">家庭住址：</span>
          <span class="text-value">{{otherInfo.jtzz}}</span>
        </div>
        <div class="row">
          <span class="text-field">手机号码：</span>
          <span class="text-value">{{data.phone || '-'}}</span>
        </div>
        <div class="row">
          <span class="text-field">QQ号或微信号：</span>
          <span class="text-value">{{otherInfo.qqorwx || '-'}}</span>
        </div> -->
        <div class="row">
          <span class="text-field">学历：</span>
          <span class="text-value">{{data.education || '-'}}</span>
        </div>
        <div class="row">
          <span class="text-field">学位：</span>
          <span class="text-value">{{data.degree || '-'}}</span>
        </div>
        <!-- <div class="row">
          <span class="text-field">毕业院校：</span>
          <span class="text-value">{{otherInfo.byyx || '-'}}</span>
        </div>
        <div class="row">
          <span class="text-field">系及专业：</span>
          <span class="text-value">{{otherInfo.xjzy}}</span>
        </div>
        <div class="row">
          <span class="text-field">职业类别：</span>
          <span class="text-value">{{otherInfo.lb}}</span>
        </div>
        <div class="row">
          <span class="text-field">现工作单位及职务：</span>
          <span class="text-value">{{otherInfo.xgzdwjzw}}</span>
        </div> -->
        <div class="row">
          <span class="text-field">从事专业技术职务：</span>
          <span class="text-value">{{otherInfo.zyjszw || '-'}}</span>
        </div>
        <div class="row">
          <span class="text-field">新社会阶层类型：</span>
          <span class="text-value">{{otherInfo.shjclx || '-'}}</span>
        </div>
        <div class="row">
          <span class="text-field">聘任起始日期：</span>
          <span class="text-value">{{(otherInfo.prqsrq || '-').substr(0, 10)}}</span>
        </div>
        <div class="row">
          <span class="text-field">聘任终止日期：</span>
          <span class="text-value">{{(otherInfo.przzrq || '-').substr(0, 10)}}</span>
        </div>
        <div class="row">
          <span class="text-field">是否“农民工”：</span>
          <span class="text-value">{{otherInfo.isLmg || '-'}}</span>
        </div>
      </div>
  </r-card>
</template>

<script>
import RCard from '../components/card'
// import RImgs from '../components/imgs'
import { getWorkerInfo } from '../libs'
import { getAge } from '@/syslib/tools'
import { loadImgs } from '@/components/base/file/libv2'

export default {
  components: { RCard },
  data () {
    return {
      data: {},
      idCardSources: null,
      otherInfo: {}
    }
  },
  computed: {
    id () {
      return this.$route.query.id
    },
    age () {
      return getAge(this.data.birth, new Date())
    },
    dage () {
      return getAge(this.otherInfo.zzrq, new Date())
    }
  },
  watch: {
    id () {
      this.loadData()
    }
  },
  methods: {
    loadData () {
      getWorkerInfo(this.id).then(async data => {
        this.data = data
        this.otherInfo = data.otherInfo || {}
        const items = []
        if (data.idCardFrontFileId) {
          items.push({
            name: '正面',
            fileIds: data.idCardFrontFileId
          })
        }
        if (data.idCardBackFileId) {
          items.push({
            name: '背面',
            fileIds: data.idCardBackFileId
          })
        }
        this.idCardSources = await loadImgs(items, (item) => item.name || '身份证')
      })
    }
  },
  mounted () {
    this.loadData()
  }
}
</script>
